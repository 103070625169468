import logger from '@/utils/logger';
import { isRouteErrorResponse } from '@remix-run/react';
import { isAxiosError } from 'axios';

interface ErrorData {
  errorStatus: number;
  errorMessage: string;
  statusTitle: string;
}

export const processRouteError = (error: any): ErrorData => {
  logger.error({
    action: 'processRouteError',
    error, // Keep this log, otherwise the stack trace is lost.
  });

  let errorStatus = 500;
  let errorMessage = 'Unknown error';

  if (isRouteErrorResponse(error)) {
    errorMessage = error?.data?.message ?? error.data;
    errorStatus = error.status;
  } else if (isAxiosError(error)) {
    errorStatus = error.response?.status || errorStatus;
    errorMessage = error.response?.data?.error;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  }

  const statusTitle = errorStatus === 404 ? 'Resource Not Found' : 'Unexpected Error';

  return {
    errorStatus,
    errorMessage,
    statusTitle,
  };
};
