import * as process from 'node:process';
import pino from 'pino';

const logger = pino(
  process.env?.PRETTY_LOGS
    ? {
        // TODO: Consider log transport to cloudwatch: https://github.com/dbhowell/pino-cloudwatch
        transport: {
          target: 'pino-pretty',
          options: {
            colorizeObjects: true,
            singleLine: true,
            colorize: true,
            customColors: 'error:red,info:green,warn:yellow',
          },
        },
        serializers: { error: pino.stdSerializers.err },
        formatters: {
          level: label => {
            return { level: label };
          },
        },
        redact: [],
      }
    : {
        serializers: { error: pino.stdSerializers.err },
        timestamp: pino.stdTimeFunctions.isoTime,
        formatters: {
          level: label => {
            return { level: label };
          },
        },
      },
).child({
  build: {
    version: process.env?.npm_package_version,
  },
});

export default logger;
